<template>
  <v-container>
    <template v-if="!isWizard">
      <v-row>
        <v-col cols="9" v-if="!id">
          <h3>{{ $t('Chef.promo_title') }}</h3>
          <h5>{{ $t('Chef.promo_sub_title') }}</h5>
        </v-col>
        <v-col cols="9" v-else>
          <h4>
            This is a
            <strong>{{ promotionCategory }}</strong> promotion
          </h4>
          <h5 v-if="status === 'pause'">
            This promotion is paused
            <v-icon color="red" right>pause</v-icon>
          </h5>
          <h5 v-if="status === 'active'">
            This promotion is active
            <v-icon color="success" right>play_arrow</v-icon>
          </h5>
        </v-col>
        <v-col cols="1" v-if="id">
          <v-menu transition="scale-transition" origin="right top" bottom left>
            <template v-slot:activator="{ on }">
              <v-btn class="ma-0" icon v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list class="app-card-action">
              <v-list-item
                v-if="id"
                @click.stop="deletePromo"
                :disabled="hasRedeem"
              >
                <v-icon color="red" class="mr-3">remove</v-icon>
                <span>{{ $t('Common.delete') }}</span>
              </v-list-item>
              <v-list-item :to="'/coupons/share/' + id">
                <v-icon color="cyan" class="mr-3">share</v-icon>
                <span>{{ $t('Common.share') }}</span>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <v-row v-if="!id && !template">
        <v-col cols="12" sm="7">
          <v-radio-group v-model="promo_category" class="body-1">
            <template v-slot:label>
              <div>
                Please select type of your
                <strong>promotion</strong>
              </div>
            </template>

            <v-radio value="multiple_use">
              <template v-slot:label>
                <div>
                  <strong class="cyan--text">Multiple use:</strong> This
                  promotion can be redeemed multiple times
                </div>
              </template>
            </v-radio>
            <v-radio value="single_use">
              <template v-slot:label>
                <div>
                  <strong class="accent--text">Single use:</strong> This
                  promotion is intended to use only once.
                </div>
              </template>
            </v-radio>
            <v-radio value="menu" v-if="!isAdmin">
              <template v-slot:label>
                <div>
                  <strong class="primary--text">Menu:</strong> Promotion only
                  applies to certain menu.
                  <span class="body-2"
                    >(Customer doesn't need promo code.)</span
                  >
                </div>
              </template>
            </v-radio>
            <v-radio value="global" v-if="!isAdmin">
              <template v-slot:label>
                <div>
                  <strong class="success--text">Global:</strong> Will apply to
                  all my products.<span class="body-2"
                    >(Customer doesn't need promo code.)</span
                  >
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="5">
          <v-alert
            type="info"
            outlined
            border="top"
            dense
            v-if="promo_category === 'global'"
          >
            <small>
              With global promtion, your products will be promoted of TakeIn's
              Daily Deal.
            </small>
          </v-alert>
          <v-alert
            type="info"
            outlined
            border="top"
            dense
            v-if="promo_category === 'menu'"
          >
            <small>
              All products of the selected menus will be participting in this
              promition
            </small>
          </v-alert>
          <v-alert
            type="info"
            outlined
            border="top"
            dense
            v-if="promo_category === 'single_use'"
          >
            <small>
              This promotion is intended to use only once. For example, issue
              discount to a customer due to customer satisfaction.
            </small>
          </v-alert>
          <v-alert
            type="info"
            outlined
            border="top"
            dense
            v-if="promo_category === 'multiple_use'"
          >
            <small>
              This promotion can be redeemed multiple times. The use case is
              when your are running a promotion for all your products.
            </small>
          </v-alert>
        </v-col>
      </v-row>
      <template v-if="promo_category != undefined">
        <v-form v-model="validForm">
          <v-row align="center">
            <v-col cols="12" sm="4" v-if="promo_category === 'menu'">
              <pre v-if="false">{{ selectedMenus }}</pre>
              <v-select
                v-model="selectedMenus"
                label="Select Menu(s)"
                :items="subMenus"
                multiple
                item-text="name"
                item-value="id"
                clearable
                chips
                :rules="rules.menuRequired"
                :menu-props="{ offsetY: true }"
                return-object
              >
                <template slot="no-data">
                  <v-list-item>
                    <span class="subheading">No Menus</span>
                  </v-list-item>
                </template>
              </v-select>
              <span v-if="itemCountInMenus > 0" class="body-2"
                >Total {{ itemCountInMenus }} products will be eligible for this
                promo</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" lg="4">
              <v-text-field
                v-model="title"
                hint="For example: Summer Promotion"
                label="Promotion Title"
                maxlength="25"
                :rules="[rules.required]"
                :counter="50"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" offset-md="2">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="end_date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="formatted_end_date"
                    label="Promotion Ends"
                    append-icon="event"
                    readonly
                    @blur="end_date = parseDate(formatted_end_date)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="end_date"
                  no-title
                  scrollable
                  :min="minDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false"
                    >Cancel</v-btn
                  >
                  <v-btn text color="primary" @click="$refs.menu.save(end_date)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" lg="4">
              <v-select
                @change="changePromoType"
                label="Promotion Type"
                v-model="promo_type"
                :items="promo_types"
                item-text="name"
                item-value="value"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </v-col>
            <v-col cols="6" sm="2" offset-md="2">
              <v-text-field
                :prefix="amount_type === '$' ? '$' : ''"
                :suffix="amount_type === '%' ? '%' : ''"
                :label="
                  amount_type === '$'
                    ? 'Amount of each coupon'
                    : 'Percent discount'
                "
                type="number"
                :disabled="id != null"
                v-model="amount"
                :rules="[rules.checkAmount]"
              ></v-text-field>
            </v-col>
            <v-col cols="5" sm="4">
              <v-radio-group v-model="amount_type" row :disabled="id != null">
                <v-radio label="Amount" value="$" class="fsx-12"></v-radio>
                <v-radio label="Percent" value="%" class="fsx-12"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" sm="5" v-if="!isAdmin && needsCode">
              <small v-if="!all_products"
                >This promotion will be effective only for selected
                products</small
              >
              <v-switch
                class="pa-0 ma-0"
                v-model="all_products"
                label="Valid for all products (Global)"
              ></v-switch>
            </v-col>
          </v-row>

          <v-row v-if="promo_type.value === 'delivery'">
            <v-col cols="8" md="4">
              <v-slider
                v-model="radius"
                label="Range within Miles"
                thumb-label="always"
                min="1"
                max="10"
                step="1"
              >
                <template v-slot:thumb-label="{ value }">
                  {{ value }}
                </template>
              </v-slider>
            </v-col>
            <v-col
              v-if="amount_type === '%' && amount == 100"
              cols="4"
              offset-md="2"
            >
              <v-alert type="info" outlined border="top" dense>
                <small>Free Delivery</small>
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-if="promo_category === 'multiple_use'">
            <v-col cols="5" sm="2" lg="2">
              <v-text-field
                label="Max Redemptions"
                type="number"
                :disabled="id != null"
                v-model="max_redemptions"
              ></v-text-field>
            </v-col>
            <v-col cols="5" sm="2" offset="1" offset-sm="4">
              <v-text-field
                label="Total Coupons"
                type="number"
                :disabled="id != null"
                max="100"
                v-model="total"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-snackbar
            absolute
            bottom
            color="success"
            outlined
            v-if="showWaitAlert"
            :timeout="10000"
            @input="doneWaitAlert"
            v-model="showWaitAlert"
          >
            {{ $t('Common.wait_alert') }}
          </v-snackbar>

          <v-row align="center">
            <v-col cols="12" sm="9" md="6" lg="5">
              <template v-if="needsCode">
                <v-btn
                  v-if="!id"
                  color="success"
                  @click.stop="generate()"
                  :disabled="!valid"
                  class="ma-2"
                  >Generate codes</v-btn
                >
              </template>
              <v-btn
                color="info"
                v-if="!id"
                @click.stop="save()"
                :disabled="!validToPublish"
                class="ma-2"
                >save</v-btn
              >
              <template v-if="id != null">
                <v-btn color="info" @click.stop="save()">Save</v-btn>
                <v-btn
                  color="red white--text"
                  :disabled="isEnded || showWaitAlert"
                  @click.stop="pause()"
                  v-if="status != 'pause'"
                  class="ma-2"
                >
                  Pause
                  <v-icon right>pause</v-icon>
                </v-btn>
                <v-btn
                  :disabled="isEnded || showWaitAlert"
                  color="green white--text"
                  @click.stop="pause()"
                  v-if="status === 'pause'"
                  class="ma-2"
                >
                  Activate
                  <v-icon right>play_arrow</v-icon>
                </v-btn>

                <v-btn
                  color="success ma-2"
                  :disabled="isExpired"
                  :to="'/coupons/share/' + id"
                  >Share</v-btn
                >
              </template>
              <v-btn small class="ma-2" to="/coupons/list">cancel</v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="8"
              md="6"
              offset-lg="1"
              v-if="amount && amount_type === '$'"
            >
              <v-icon color="info">error</v-icon>
              Maximum amount of promotion if all used
              {{ (amount * max_redemptions * total) | currency }}
            </v-col>
          </v-row>
          <v-row v-if="coupons && coupons.length > 0">
            <v-col
              class="mr-1"
              cols="12"
              sm="6"
              md="4"
              v-for="(item, index) in coupons"
              :key="index"
            >
              <v-card>
                <v-card-title primary-title>{{ item.code }}</v-card-title>

                <v-card-text v-if="item.redeem_by">
                  Redeemed {{ item.redeem_by.length }} out of
                  {{ item.max_redemptions }}
                  <v-list-item
                    class="grow"
                    v-for="(redeem, index) in item.redeem_by"
                    :key="index"
                  >
                    <v-list-item-content
                      :class="index % 2 == 0 ? 'accent--text lighten-1' : ''"
                    >
                      <v-list-item-title>{{ redeem.name }}</v-list-item-title>
                      <v-list-item-title>
                        <small v-if="redeem.at">
                          {{ $moment(redeem.at).format('l h:ma') }}
                        </small>
                        <small v-if="redeem.redeemDate">
                          {{ redeem.redeemDate | formatDate }}
                        </small>
                        <small v-if="redeem.buyer"
                          >by {{ redeem.buyer.name }}</small
                        >
                        <small v-if="redeem.orderId" class="mx-1 info--text">
                          <router-link :to="'/order/view/' + redeem.orderId"
                            >view order</router-link
                          >
                        </small>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12" sm="3">
              <v-text-field
                @change="checkCode(list[index])"
                :disabled="id != null"
                placeholder="promotion code"
                v-model="list[index]"
                :error-messages="errors[item]"
                v-for="(item, index) in list"
                :key="index"
                >{{ hasError }}</v-text-field
              >
            </v-col>
          </v-row>
        </v-form>
      </template>
      <delete-confirmation-dialog
        ref="deleteConfirmationDialog"
        heading="Deleting Promotion"
        message="Are you sure you want to delete this promotion?"
        @onConfirm="onDeletePromo"
      ></delete-confirmation-dialog>
    </template>
    <template v-else>
      <coupon-wizard />
    </template>
  </v-container>
</template>

<script>
import coupon from 'coupon-code'
import moment from 'moment'
import CouponWizard from './CouponWizard'
import { mapGetters } from 'vuex'

export default {
  components: {
    CouponWizard,
    PaginationAlert: () =>
      import('fod-core/src/components/common/PaginationAlert.vue')
  },
  data() {
    return {
      isWizard: false,
      uid: null,
      template: null,
      takein: false,
      title: null,
      amount: 0,
      end_date: null,
      menu: false,
      max_redemptions: 1,
      total: 1,
      status: 'active',
      id: null,
      coupons: [],
      errors: {},
      hasError: false,
      all_products: true,
      list: [],
      subMenus: [],
      subMenuNames: [],
      selectedMenus: [],
      amount_type: '$',
      now: null,
      radius: null,
      promo_category: null,
      updatedTime: null,
      pauseInterval: 1,
      showWaitAlert: false,
      validForm: false,

      formatted_end_date: this.formatTime(
        new Date().toISOString().substr(0, 10)
      ),
      promo_type: { name: 'Price Discount', value: 'price' },
      promo_types: [
        { name: 'Price Discount', value: 'price' },
        { name: 'Delivery Discount', value: 'delivery' }
      ],
      rules: {
        required: value => !!value || 'Value Required',
        menuRequired: [v => v.length > 0 || 'Required at least 1 item'],

        checkAmount: value => {
          if (value) {
            if (value <= 0) {
              return 'Wrong value'
            }
            if (this.amount_type === '%') {
              if (value > 100) {
                return 'Wrong value'
              }
            }
          }
          return true
        }
      }
    }
  },
  watch: {
    errors: {
      handler() {
        console.log(`Watch for `, this.errors)
      },
      deep: true
    },
    end_date() {
      this.formatted_end_date = this.formatTime(this.end_date)
    },
    $route: function() {
      this.isWizard = this.$route.query.wizard === 'true'
      if (this.isAdmin && !this.$route.params.uid) {
        this.uid = 'TAKEIN'
      } else
        this.uid =
          this.isAdmin && this.$route.params.uid
            ? this.$route.params.uid
            : this.profile
            ? this.profile.id
            : null
      if (this.$route.params.id) {
        this.id = this.$route.params.id
        this.getPromotion()
      } else {
        this.template = this.$route.query.template
        this.createByTemplate(this.$route.query.template)
      }
      this.$store.dispatch('loadMenus').then(list => {
        this.subMenus = list.filter(menu => menu.type != 'parent')
      })
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin',
      profile: 'profile'
    }),
    valid() {
      return (
        this.title && this.title.length <= 25 && this.amount && this.end_date
      )
    },
    needsCode() {
      return (
        this.promo_category === 'multiple_use' ||
        this.promo_category === 'single_use'
      )
    },
    validToPublish() {
      let errs = Object.keys(this.errors).length
      if (this.needsCode) {
        return (
          !this.takein &&
          this.title &&
          this.amount &&
          errs < 1 &&
          this.list &&
          this.list.length > 0
        )
      }
      if (this.promo_category === 'menu' && this.selectedMenus.length == 0)
        return false

      return this.title && this.amount && errs < 1
    },
    isExpired() {
      if (!this.end_date) {
        return false
      }
      const today = new Date()
      today.setDate(today.getDate())
      const end = new Date(this.end_date)
      if (today > end) {
        console.log('Coupon is expired. Can not publish.')
        return true
      } else {
        return false
      }
    },
    minDate() {
      const date = new Date()
      date.setDate(date.getDate() - 1)
      return date.toISOString().substr(0, 10)
    },
    hasRedeem() {
      let found = false
      if (this.coupons) {
        this.coupons.forEach(coupon => {
          if (coupon.redeem_by && coupon.redeem_by.length > 0) {
            found = true
            return
          }
        })
      }
      return found
    },
    isEnded() {
      return this.end_date
        ? moment(this.end_date)
            .add(1, 'd')
            .isBefore(moment())
        : false
    },
    promotionCategory() {
      return this.promo_category
        ? this.promo_category.replace('_', ' ')
        : 'Multi Use'
    },
    itemCountInMenus() {
      let items = new Set()

      if (this.selectedMenus) {
        this.selectedMenus.forEach(menu => {
          menu.items.forEach(item => items.add(item))
        })
      }
      return items.size
    }
  },
  mounted() {
    this.isWizard = this.$route.query.wizard === 'true'

    if (this.isAdmin && !this.$route.params.uid) {
      this.uid = 'TAKEIN'
    } else
      this.uid =
        this.isAdmin && this.$route.params.uid
          ? this.$route.params.uid
          : this.profile
          ? this.profile.id
          : null
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      this.getPromotion()
    }
    this.$store.dispatch('loadMenus').then(list => {
      this.subMenus = list.filter(menu => menu.type != 'parent')
    })
  },
  // beforeRouteLeave(to, from, next) {
  // this.$refs.paginationAlert.openDialog()
  // this.$nextTick(() => {
  //   this.$refs.paginationAlert.$once('onYes', () => {
  //     next(true)
  //   })
  //   this.$refs.paginationAlert.$once('onCancel', () => {
  //     next(false)
  //   })
  // })
  // },
  methods: {
    getPromotion() {
      this.$store
        .dispatch('getPromotion', { id: this.$route.params.id, uid: this.uid })
        .then(data => {
          if (data) {
            this.title = data.title
            this.status = data.status
            this.amount = data.amount
            this.end_date = data.end_date
            this.max_redemptions = data.max_redemptions
            this.total = data.total
            this.amount_type = data.amount_type
            this.radius = data.radius
            this.list = data.list
            this.selectedMenus = data.selectedMenus || []

            this.promo_category =
              data.promo_category == undefined
                ? 'multiple_use'
                : data.promo_category
            this.all_products = data.all_products
            if (data.promo_type) {
              this.promo_type = data.promo_type
            }
            this.$store
              .dispatch('getCoupons', {
                load: false,
                uid: this.uid,
                id: this.id
              })
              .then(list => {
                this.coupons = list
              })
          }
        })
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatTime(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    generate() {
      let list = []
      if (this.isAdmin) {
        list = ['TAKEINxxx']
      } else {
        for (var i = 0; i < this.total; i++) {
          list.push(coupon.generate({ partLen: 6 }))
        }
      }

      this.list = list
    },
    save() {
      if (!this.validToPublish) {
        console.warn('Coupon is not valid to be published')
        return
      }
      this.updatedTime = moment.utc().valueOf()
      this.$store
        .dispatch('savePromotion', {
          uid: this.uid,
          id: this.id,
          title: this.title,
          amount: parseFloat(this.amount),
          end_date: this.end_date,
          promo_category: this.promo_category,
          promo_type: this.promo_type,
          max_redemptions: parseInt(this.max_redemptions),
          total: this.total,
          list: this.list,
          radius: this.radius,
          all_products: this.all_products,
          amount_type: this.amount_type,
          selectedMenus: this.selectedMenus || null,
          updatedTime: this.updatedTime
        })
        .then(id => {
          if (!this.id) this.id = id
          console.log(this.end_date, this.id, 'www')
        })
    },
    pause() {
      this.showWaitAlert = true
      if (this.status === 'pause') {
        this.status = 'active'
      } else {
        this.status = 'pause'
      }
      this.updatedTime = moment.utc().valueOf()
      this.$store.dispatch('savePromotion', {
        uid: this.uid,
        id: this.id,
        status: this.status,
        updatedTime: this.updatedTime
      })
    },
    deletePromo() {
      this.$refs.deleteConfirmationDialog.openDialog()
    },
    onDeletePromo() {
      this.$refs.deleteConfirmationDialog.close()
      if (this.id) {
        this.$store
          .dispatch('deletePromotion', { id: this.id, uid: this.uid })
          .then(() => {
            this.$router.push('/coupons/list')
          })
      } else {
        this.$router.push('/coupons/list')
      }
    },
    checkCode(code) {
      console.log('Checking ', code)
      if (!this.isAdmin) {
        if (code.toLowerCase().indexOf('takein') == 0) {
          this.$store.dispatch('setMessage', {
            title: 'Takein promo code is reserved',
            body: `Code can't start with takein. Please use different code`
          })
          this.takein = true
          return
        }
      } else {
        if (code.toLowerCase().indexOf('takein') != 0) {
          this.$store.dispatch('setMessage', {
            title: 'Promo code should start with takein',
            body: `You are admin, takein promot code has to start with TakeIn`
          })
          this.takein = true
          return
        }
      }

      this.takein = false
      this.$store.dispatch('getCoupon', { code: code }).then(c => {
        console.log('Result ', c)

        if (c) {
          this.hasError = true
          this.errors[code] = `Code exists  ${code}`
        } else {
          //delete this.errors[code]
          //console.log('11111111111111 ', Object.keys(this.errors))
          this.errors = {}
          this.hasError = false
        }
      })
    },

    changePromoType() {
      if (this.promo_type.value === 'delivery') {
        this.amount_type = '%'
        this.amount = '100'
      }
    },
    enoughTimePassed() {
      if (!this.updatedTime) {
        return true
      }
      const time = this.updatedTime + this.pauseInterval * 60000
      if (time < moment.utc().valueOf()) {
        return true
      }
      return false
    },
    doneWaitAlert() {
      console.log('Done')
    },
    createByTemplate(template) {
      if (!template) {
        return
      }
      this.createBaseTemplate()
      switch (template) {
        case 'free_delivery':
          this.promo_category = 'gobal'
          this.promo_type = { value: 'delivery' }
          this.title = 'Free Delivery ' + moment().format('MM-YY')
          this.amount = '100'
          this.amount_type = '%'
          this.radius = 3

          break
        case '10_off_menu':
          this.promo_category = 'menu'
          this.promo_type = { value: 'price' }
          this.title = '10% of off menu ' + moment().format('MM-YY')
          this.amount = '10'
          this.amount_type = '%'
          break
        default:
          break
      }
    },
    createBaseTemplate() {
      this.promo_category = 'gobal'
      this.promo_type = { value: 'delivery' }
      this.title = 'Free Delivery ' + moment().format('MM-YY')
      this.amount = '100'
      this.amount_type = '%'
      this.end_date = moment()
        .add(1, 'M')
        .format('YYYY-MM-DD')
      this.formatted_end_date = this.formatTime(
        moment()
          .add(1, 'M')
          .format('YYYY-MM-DD')
      )
    }
  }
}
</script>

<style>
.fsx-12 .v-label {
  font-size: 0.8em;
}
</style>
