<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="10" lg="8">
        <v-card min-height="420px">
          <v-card-title
            ><v-icon class="mr-1">mdi-auto-fix</v-icon> Coupon
            Wizard</v-card-title
          >
          <v-divider class="my-0"></v-divider>
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step step="1"> </v-stepper-step>
              <v-divider></v-divider>

              <v-stepper-step step="2"> </v-stepper-step>
              <v-divider></v-divider>

              <v-stepper-step step="3"> </v-stepper-step>
              <v-divider></v-divider>

              <v-stepper-step step="4"> </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card class="pa-4">
                  <v-card-text class="py-1">
                    Sample Promotions templates:
                    <v-btn
                      link
                      outlined
                      small
                      color="info"
                      class="mx-1"
                      @click="createFromTemplate('free_delivery')"
                      >Free Delivery within 3 miles</v-btn
                    >
                    <v-btn
                      link
                      outlined
                      small
                      color="info"
                      @click="createFromTemplate('10_off_menu')"
                      >10% off of a menu</v-btn
                    >
                  </v-card-text>
                  <v-card-title>Or Select Promotion Type</v-card-title>
                  <v-card-text class="pa-8">
                    <v-row>
                      Please select type of your
                      <strong class="ml-1"> Promotion</strong>
                    </v-row>
                    <v-row>
                      <v-radio-group v-model="promo_category">
                        <v-radio value="multiple_use">
                          <template v-slot:label>
                            <strong class="cyan--text">Multiple Use</strong>
                          </template>
                        </v-radio>
                        <v-radio value="single_use">
                          <template v-slot:label>
                            <strong class="accent--text">Single Use</strong>
                          </template>
                        </v-radio>
                        <v-radio value="menu" v-if="!isAdmin">
                          <template v-slot:label>
                            <strong class="primary--text">Menu</strong>
                          </template>
                        </v-radio>
                        <v-radio value="global" v-if="!isAdmin">
                          <template v-slot:label>
                            <strong class="success--text">Global</strong>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-row>
                    <v-row>
                      <v-alert
                        type="info"
                        outlined
                        border="top"
                        dense
                        v-if="promo_category === 'global'"
                      >
                        <small>
                          With global promtion, your products will be promoted
                          of TakeIn's Daily Deal. This will apply to all my
                          products. Customer doesn't need promo code.
                        </small>
                      </v-alert>
                      <v-alert
                        type="info"
                        outlined
                        border="top"
                        dense
                        v-if="promo_category === 'menu'"
                      >
                        <small>
                          All products of the selected menus will be
                          participting in this promition. Promotion only applies
                          to certain menu. Customer doesn't need promo code.
                        </small>
                      </v-alert>
                      <v-alert
                        type="info"
                        outlined
                        border="top"
                        dense
                        v-if="promo_category === 'single_use'"
                      >
                        <small>
                          This promotion is intended to use only once. For
                          example, issue discount to a customer due to customer
                          satisfaction.
                        </small>
                      </v-alert>
                      <v-alert
                        type="info"
                        outlined
                        border="top"
                        dense
                        v-if="promo_category === 'multiple_use'"
                      >
                        <small>
                          This promotion can be redeemed multiple times. The use
                          case is when your are running a promotion for all your
                          products.
                        </small>
                      </v-alert>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn color="primary" @click="e1 = 2">
                      Next
                    </v-btn></v-card-actions
                  >
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card class="pa-4">
                  <v-card-title>Set Title and Expiration</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-select
                        v-model="selectedMenus"
                        label="Select Menus"
                        :items="subMenus"
                        multiple
                        item-text="name"
                        item-value="id"
                        clearable
                        chips
                        :menu-props="{ offsetY: true }"
                        return-object
                        class="mx-3"
                        v-if="promo_category === 'menu'"
                      >
                        <template slot="no-data">
                          <v-list-item>
                            <span class="subheading">No Menus</span>
                          </v-list-item>
                        </template>
                      </v-select>
                      <span v-if="itemCountInMenus > 0"
                        >Total Items in menus: {{ itemCountInMenus }}</span
                      >
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="title"
                          hint="For example: Summer Promotion"
                          label="Promotion Title"
                          maxlength="25"
                          :rules="[rules.required]"
                          counter
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="end_date"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formatted_end_date"
                              label="Promotion Ends"
                              append-icon="event"
                              readonly
                              @blur="end_date = parseDate(formatted_end_date)"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="end_date"
                            no-title
                            scrollable
                            :min="minDate"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu.save(end_date)"
                              >OK</v-btn
                            >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end"
                    ><v-btn text class="mr-2" @click="e1 = 1">
                      Back
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="e1 = 3"
                      :disabled="
                        !title || !end_date || (title && title.length > 25)
                      "
                    >
                      Next
                    </v-btn></v-card-actions
                  >
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-card class="pa-4">
                  <v-card-title
                    >Set Value of Promo

                    <small
                      class="mx-1 info--text"
                      v-if="
                        promo_type.value === 'delivery' &&
                          amount_type === '%' &&
                          amount == 100
                      "
                      >Free Delivery</small
                    >
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="8" md="5">
                        <v-select
                          @change="changePromoType"
                          label="Promotion Type"
                          v-model="promo_type"
                          :items="promo_types"
                          item-text="name"
                          item-value="value"
                          persistent-hint
                          return-object
                          single-line
                        ></v-select>
                      </v-col>

                      <v-col cols="6" md="3" offset-md="1">
                        <v-text-field
                          :prefix="amount_type === '$' ? '$' : ''"
                          :suffix="amount_type === '%' ? '%' : ''"
                          :label="
                            amount_type === '$'
                              ? 'Amount of each coupon'
                              : 'Percent discount'
                          "
                          type="number"
                          v-model="amount"
                          :rules="[rules.checkAmount]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="3">
                        <v-radio-group v-model="amount_type" row>
                          <v-radio
                            label="Amount"
                            value="$"
                            class="fsx-12"
                          ></v-radio>
                          <v-radio
                            label="Percent"
                            value="%"
                            class="fsx-12"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row v-if="promo_category === 'multiple_use'">
                      <v-col cols="12">
                        <small v-if="!all_products"
                          >This promotion will be effective only for selected
                          products</small
                        >
                        <v-switch
                          class="pa-0 ma-0"
                          v-model="all_products"
                          label="Valid for all products (Global)"
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        v-if="
                          promo_category === 'multiple_use' &&
                            amount &&
                            amount_type === '$'
                        "
                      >
                        <v-icon color="info">error</v-icon>
                        Maximum amount of promotion if all used
                        {{ (amount * max_redemptions * total) | currency }}
                      </v-col>
                    </v-row>
                    <v-row v-if="promo_type.value === 'delivery'">
                      <v-col cols="6">
                        <v-slider
                          v-model="radius"
                          label="Range within Miles"
                          thumb-label="always"
                          min="1"
                          max="10"
                          step="1"
                        >
                          <template v-slot:thumb-label="{ value }">
                            {{ value }}
                          </template>
                        </v-slider>
                      </v-col>
                    </v-row>
                    <v-row v-if="promo_category === 'multiple_use'">
                      <v-col cols="6">
                        <v-text-field
                          label="Max Redemptions"
                          type="number"
                          v-model="max_redemptions"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="Total Coupons"
                          type="number"
                          max="100"
                          v-model="total"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end"
                    ><v-btn text class="mr-2" @click="e1 = 2">
                      Back
                    </v-btn>
                    <v-btn color="primary" :disabled="!amount" @click="e1 = 4">
                      Next
                    </v-btn></v-card-actions
                  >
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="4">
                <v-card class="pa-4">
                  <v-card-title>Generate or Save</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-btn
                        v-if="needsCode"
                        color="success"
                        @click.stop="generate()"
                        :disabled="!valid"
                        class="ma-2"
                        ><v-icon>mdi-cogs</v-icon>Generate Codes</v-btn
                      >
                      <v-btn
                        color="info"
                        @click.stop="save()"
                        :disabled="!validToPublish"
                        class="ma-2"
                        ><v-icon>mdi-content-save</v-icon>Save</v-btn
                      >
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          @change="checkCode(list[index])"
                          placeholder="Promotion Code"
                          v-model="list[index]"
                          :error-messages="errors[item]"
                          v-for="(item, index) in list"
                          :key="index"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end"
                    ><v-btn text class="mr-2" @click="e1 = 3">
                      Back
                    </v-btn>
                    <v-btn
                      color="primary"
                      :disabled="!promo_id"
                      @click="finish"
                    >
                      Finish
                    </v-btn></v-card-actions
                  >
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          <v-card-text> </v-card-text>
          <v-divider class="my-0"></v-divider>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import coupon from 'coupon-code'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {
      uid: null,
      promo_id: null,
      promo_category: 'multiple_use',
      selectedMenus: [],
      subMenus: [],
      all_products: true,
      menu: false,
      max_redemptions: 1,
      amount_type: '$',
      amount: 0,
      total: 1,
      title: null,
      takein: false,
      coupons: [],
      radius: 3,
      errors: {},
      hasError: false,
      list: [],
      e1: 1,
      updatedTime: null,
      end_date: moment()
        .add(1, 'M')
        .format('YYYY-MM-DD'),
      formatted_end_date: this.formatTime(
        moment()
          .add(1, 'M')
          .format('YYYY-MM-DD')
      ),
      promo_type: { name: 'Price Discount', value: 'price' },
      promo_types: [
        { name: 'Price Discount', value: 'price' },
        { name: 'Delivery Discount', value: 'delivery' }
      ],
      rules: {
        required: value => !!value || 'Value Required',
        checkAmount: value => {
          if (value) {
            if (value <= 0) {
              return 'Wrong value'
            }
            if (this.amount_type === '%') {
              if (value > 100) {
                return 'Wrong value'
              }
            }
          }
          return true
        }
      }
    }
  },
  watch: {
    errors: {
      handler() {
        console.log(`Watch for `, this.errors)
      },
      deep: true
    },
    end_date() {
      this.formatted_end_date = this.formatTime(this.end_date)
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin',
      profile: 'profile'
    }),
    valid() {
      return (
        this.title && this.title.length <= 25 && this.amount && this.end_date
      )
    },
    needsCode() {
      return (
        this.promo_category === 'multiple_use' ||
        this.promo_category === 'single_use'
      )
    },
    validToPublish() {
      let errs = Object.keys(this.errors).length
      if (this.needsCode) {
        return (
          !this.takein &&
          this.title &&
          this.amount &&
          errs < 1 &&
          this.list &&
          this.list.length > 0
        )
      }
      if (this.promo_category === 'menu' && this.selectedMenus.length == 0)
        return false

      return this.title && this.amount && errs < 1
    },
    minDate() {
      const date = new Date()
      date.setDate(date.getDate() - 1)
      return date.toISOString().substr(0, 10)
    },
    itemCountInMenus() {
      let items = new Set()

      if (this.selectedMenus) {
        this.selectedMenus.forEach(menu => {
          menu.items.forEach(item => items.add(item))
        })
      }
      return items.size
    }
  },
  mounted() {
    if (this.isAdmin && !this.$route.params.uid) {
      this.uid = 'TAKEIN'
    } else
      this.uid =
        this.isAdmin && this.$route.params.uid
          ? this.$route.params.uid
          : this.profile
          ? this.profile.id
          : null

    this.$store.dispatch('loadMenus').then(list => {
      this.subMenus = list.filter(menu => menu.type != 'parent')
    })
  },

  methods: {
    finish() {
      this.$router.push(`/coupons/edit/${this.promo_id}`)
    },
    checkCode(code) {
      console.log('Checking ', code)
      if (!this.isAdmin) {
        if (code.toLowerCase().indexOf('takein') == 0) {
          this.$store.dispatch('setMessage', {
            title: 'Takein promo code is reserved',
            body: `Code can't start with takein. Please use different code`
          })
          this.takein = true
          return
        }
      } else {
        if (code.toLowerCase().indexOf('takein') != 0) {
          this.$store.dispatch('setMessage', {
            title: 'Promo code should start with takein',
            body: `You are admin, takein promot code has to start with TakeIn`
          })
          this.takein = true
          return
        }
      }

      this.takein = false
      this.$store.dispatch('getCoupon', { code: code }).then(c => {
        console.log('Result ', c)

        if (c) {
          this.hasError = true
          this.errors[code] = `Code exists  ${code}`
        } else {
          this.errors = {}
          this.hasError = false
        }
      })
    },
    save() {
      if (!this.validToPublish) {
        console.warn('Coupon is not valid to be published')
        return
      }

      this.updatedTime = moment.utc().valueOf()
      this.$store
        .dispatch('savePromotion', {
          uid: this.uid,
          title: this.title,
          amount: parseFloat(this.amount),
          end_date: this.end_date,
          promo_category: this.promo_category,
          promo_type: this.promo_type,
          max_redemptions: parseInt(this.max_redemptions),
          total: this.total,
          radius: this.radius,
          list: this.list,
          all_products: this.all_products,
          amount_type: this.amount_type,
          selectedMenus: this.selectedMenus || null,
          updatedTime: this.updatedTime
        })
        .then(id => {
          if (id) this.promo_id = id
        })
    },
    generate() {
      let list = []
      if (this.isAdmin) {
        list = ['TAKEINxxx']
      } else {
        for (var i = 0; i < this.total; i++) {
          list.push(coupon.generate({ partLen: 6 }))
        }
      }

      this.list = list
    },
    changePromoType() {
      if (this.promo_type.value === 'delivery') {
        this.amount_type = '%'
        this.amount = '100'
      }
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatTime(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    createFromTemplate(id) {
      this.$router.push(`/coupons/new?template=` + id)
    }
  }
}
</script>

<style>
.fsx-12 .v-label {
  font-size: 0.8em;
}
</style>
